<template>
    <div>
        <el-dialog
            title="选择范围"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1000px">
            <div class="formContent">
                <el-table
                    v-if="step == 1"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    max-height="500px"
                    style="width: 100%">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column
                        label="姓名"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="手机号"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="商户"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName }}</span>
                        </template>
                    </el-table-column>
                </el-table>
    
                <el-form ref="form" label-position="left" label-width="0" v-if="step == 2">
                    <el-form-item prop="mark" label='' v-for="(item, index) in selectData" :key="index">
                        <div class="a-flex-cfsfs">
                            <span>{{ index + 1 }}：{{ item.userName+"("+item.mobile+")" }} {{ item.companyName }}</span>
                            <div class="a-flex-rfsc a-mb-10" v-for="(ite, idx) in item.alertMessageMethodList" :key="idx">
                                <span>{{ util.initDic(ite.checkSuccess, 'alertCheckType') }}</span>
                                <jd-input-intnum :disabled='disable?true:false' maxlength='4' v-model="ite.delayMinutes" placeholder="输入0，则立即推送预警消息" style="margin-left: 10px;width: 200px"></jd-input-intnum>
                                <span class="a-plr-10">分钟后，以</span>
                                <el-select v-model="ite.messageType" :disabled='disable?true:false' placeholder="请选择通知方式">
                                    <el-option 
                                        v-for="(it,ix) in messageTypeDic"
                                        :key="ix"
                                        :label="it.label"
                                        :value="it.value">
                                    </el-option>
                                </el-select>
                                <span class="a-ml-10">方式通知预警人员</span>
                                <i class="a-c-blue el-icon-circle-plus-outline a-ml-20 a-fs-18" @click="handleAdd(index)" v-if="idx == 0"></i>
                                <i class="a-c-error el-icon-remove-outline a-ml-20 a-fs-18" @click="handleReduce(index,idx)" v-if="item.alertMessageMethodList.length > 2 && idx > 0"></i>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" v-if="!disable">
                <el-button class="plainBtn" @click="dialogVisible = false; step = 1">取消</el-button>
                <!-- <el-button type="primary"  @click="submit">提交</el-button> -->
                <el-button type="primary"  @click="nextStep" v-if="step == 1">下一步</el-button>
                <el-button type="primary"  @click="submit" v-if="step == 2">提交</el-button>
            </div>
            <div slot="footer" v-else>
                <el-button type="primary"  @click="dialogVisible = false; step = 1; disable = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import util from '@/utils/util'
    export default {
        data() {
            return {
                util,
                step: 1,
                disable: false,
                dialogVisible: false,
                tableData: [],
                selectData: [],
                messageTypeDic: [],
            };
        },
        watch:{
            dialogVisible: {
                handler (val) {
                    if(!val) {
                        this.step = 1
                    }
                    this.selectData = JSON.parse(JSON.stringify(this.selectData))
                }
            }
        },
        mounted () {
            this.$getDic('alertMessageType','select').then(res=>{ 
                this.messageTypeDic = res.map(item=>{
                    return {
                        label: item.label,
                        value: Number(item.value)
                    }
                })
            })
        },
        methods:{
            handleSelectionChange (selection) {
                this.selectData = selection
            },
            nextStep () {
                if(!this.selectData.length){
                    this.$message.error('请至少选择一个预警人员')
                    return
                }
                this.step++
            },
            handleReduce (index, idx) {
                this.selectData[index].alertMessageMethodList.splice(idx,1)
            },
            handleAdd (index) {
                this.selectData[index].alertMessageMethodList.push({
                    messageType: "", 
                    checkSuccess: 1, 
                    delayMinutes: ""
                })
            },
            submit () {
                for (let index = 0; index < this.selectData.length; index++) {
                    const errorItem = this.selectData[index].alertMessageMethodList.find(res=>{
                        return res.delayMinutes === "" || res.messageType === ""
                    })
                    if(errorItem){
                        this.$message.error('请完整填写预警人员的预警方式')
                        return
                    }
                }
                this.dialogVisible = false
                this.step = 1
                this.$emit('success',this.selectData)
                this.$set(this, 'selectData', []);
            },


        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .a-mb-10{
        margin-bottom: 10px;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .rangeName{
        min-width: 100px;
    }
    .send-user{
        background: #53A8FF;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px 10px 0;
        .send-user-line{
            width: 1px;
            height: 16px;
            // background: #ffffff;
            margin: 0 5px;
        }
    }
</style>